import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import {
  useUser,
  useMapi,
  PackageCard,
  LeshenPhoneCTA,
} from '@leshen/gatsby-theme-leshen'
import {
  VariableContent,
  Columns,
  Stack,
  scrollTo,
  Typography,
} from '@leshen/ui'
import _get from 'lodash.get'
import useAvailablePlans from '../hooks/useAvailablePlans'
import useHasMounted from '../hooks/useHasMounted'
import zipLogic from '../utils/zipLogic'
import Spinner from './Spinner'
import theme from '../../theme'

const ZipPackageSection = () => {
  const { userData, userLocation, setUserData } = useUser()
  const mapiData = useMapi()
  const phoneNumber = _get(mapiData, 'rotatedNumber')
  const { availablePlans, setAvailablePlans } = useAvailablePlans(userData)
  const arePackagesLoading = userData && userData.loading

  useEffect(() => {
    if (userLocation?.zipCode?.length === 5 && !userData) {
      ;(async () => {
        const packageData = await zipLogic(userLocation.zipCode)
        setUserData({ loading: false, businessCenturylinkService: packageData })
      })()
    }
    if (availablePlans) {
      scrollTo('#loadingScrollTarget')
    }
    if (!userData && availablePlans) {
      return
    }
    setAvailablePlans(userData)
  }, [userData, setUserData, availablePlans, setAvailablePlans, userLocation])

  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  const havePackages =
    availablePlans?.businessCenturylinkService?.packages?.length > 0

  return (
    <>
      {arePackagesLoading && (
        <VariableContent
          className="packages"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Searching packages in your area.
              </Typography>
              <Spinner />
            </>
          }
        />
      )}

      {havePackages && !arePackagesLoading && (
        <VariableContent
          className="packages"
          alignMainContent="center"
          mainContent={
            <div>
              {userLocation.city && userLocation.zipCode && (
                <Typography variant="h4">
                  <strong>
                    Showing CenturyLink plans for {userLocation.city},{' '}
                    {userLocation.zipCode}
                  </strong>
                </Typography>
              )}
            </div>
          }
        >
          <Stack spacing="xxl">
            <Columns>
              {availablePlans.businessCenturylinkService.packages.map(
                (data) => (
                  <PackageCard
                    label={data.label}
                    packageData={{ ...data.brandy }}
                    content={
                      <LeshenPhoneCTA variant="feature" color="primary">
                        Call
                      </LeshenPhoneCTA>
                    }
                    key={data.label}
                    modal={
                      <span>
                        <a href="/terms-conditions">Offer Details</a>
                      </span>
                    }
                  />
                )
              )}
            </Columns>
          </Stack>
        </VariableContent>
      )}

      {(!havePackages ||
        availablePlans?.businessCenturylinkService?.Product === 'OOA') &&
        !arePackagesLoading && (
          <VariableContent
            alignMainContent="center"
            mainContent={
              <div>
                <Typography variant="h2">Oh no! You’re Out of Area.</Typography>
                <Typography variant="h6">
                  <strong>
                    It looks like CenturyLink does not provide coverage in your
                    zip.{' '}
                    <StyledButtonLink
                      type="button"
                      onClick={() => scrollTo('h1')}
                    >
                      change
                    </StyledButtonLink>
                  </strong>
                </Typography>
                <Typography>
                  Don’t go just yet! Try entering another zip code, or give a
                  CenturyLink representative a call at{' '}
                  <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                </Typography>
              </div>
            }
          />
        )}

      {availablePlans &&
        !availablePlans.loading &&
        availablePlans.businessCenturylinkService === null && (
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  We&lsquo;re having trouble locating service options for your
                  area.
                </Typography>
                <p>
                  Give us a call and one of our Internet specialists can help
                  get you connected!
                </p>
              </>
            }
            alignMainContent="center"
          />
        )}
    </>
  )
}

export default ZipPackageSection

const StyledButtonLink = styled.button(
  () => css`
    color: ${theme.colors.primary};
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    display: inline;
    font-weight: bold;
  `
)
